import { GetServerSideProps } from 'next'

import { ApplicationPage } from 'src/types/page-props'

import Seo from '@concepts/Seo/Seo'
import { NOT_FOUND } from '@concepts/Seo/constants/pages'
import Layout from '@templates/Layout/Layout'
import NotFoundPageContent from '@organisms/NotFoundPageContent/NotFoundPageContent'

import { withPublisher } from '@middlewares/tenancy/publisher'

const NotFound: ApplicationPage = () => {
  return (
    <Layout>
      <Seo page={NOT_FOUND} />
      <NotFoundPageContent />
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  return withPublisher(context, {}, { fetchSSRUser: false })
}

export default NotFound
