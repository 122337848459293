import Container from '@atoms/UIContainer/Container'

import RelatedSales from '@concepts/RelatedSales/views/RelatedSales'
import { Text } from '@lib/UIComponents'

const NotFoundPageContent = () => (
  <>
    <Container sx={{ textAlign: 'center' }}>
      <Text as="h2">Oops! It looks like the page does not exist.</Text>
      <Text as="p">
        In the mean time, check out these sweet deals we have going on right
        now!
      </Text>
    </Container>
    <RelatedSales title="" saleIds={[]} limit={8} />
  </>
)

export default NotFoundPageContent
