import { Box, SxStyleProp } from 'rebass'

import useProductRecommendations from '@concepts/ProductRecommendations/hooks/useProductRecommendations'
import Showcase from '@molecules/Showcase/Showcase'
import { isPresent } from '@utils/logic'
import { Sale } from 'src/generated/graphql'
import segmentAnalytics from '@lib/segment/analytics'
import { usePublisherContext } from '@concepts/Publisher/store/context'

export type RelatedSalesType = {
  sx?: SxStyleProp
  title?: string
  limit?: number
  saleIds: Array<number>
}

const RelatedSales: React.FC<React.PropsWithChildren<RelatedSalesType>> = ({
  title = 'People Also Viewed These Items',
  limit = 12,
  sx = {},
  saleIds
}) => {
  const { recommendations } = useProductRecommendations(true, {
    excludeSaleIds: saleIds,
    limit
  })
  const displayRecommendations = isPresent(recommendations)
  const { databaseId } = usePublisherContext()
  const trackClickOnProductRecommendation = (item: Sale): void =>
    segmentAnalytics.clickOnProductRecommendation({
      productName: item.name,
      currentUrl: location.href,
      publisherId: databaseId as number
    })

  if (!displayRecommendations) return null

  return (
    <Box id="relatedProducts">
      <Box sx={sx}>
        <Showcase
          as="h4"
          imageId="fourColumnImage"
          title={title}
          items={recommendations}
          onClickItem={trackClickOnProductRecommendation}
        />
      </Box>
    </Box>
  )
}

export default RelatedSales
