import { useEffect, useState } from 'react'

import { Sale } from 'src/generated/graphql'
import { useApollo } from '@lib/apollo/index'

import ProductRecommendationRepository from '../repository/ProductRecommendationRepository'
import ProductRecommendationsDTO from '../dto/ProductRecommendationsDTO'

const useProductRecommendations = (
  shouldFetch: boolean,
  params: {
    limit?: number
    applyDefaultRules?: boolean
    onlyDigitalSales?: boolean
    excludeSaleIds?: Array<number>
  }
): {
  recommendations: Array<Sale>
} => {
  const apolloClient = useApollo()
  const [recommendations, setRecommendations] = useState<Array<Sale>>([])
  const { limit, excludeSaleIds } = params

  useEffect(() => {
    if (shouldFetch) {
      ProductRecommendationRepository.search(apolloClient, params).then(
        (recommendations) =>
          setRecommendations(ProductRecommendationsDTO.parse(recommendations))
      )
    }
  }, [apolloClient, shouldFetch, limit, excludeSaleIds])

  return {
    recommendations
  }
}

export default useProductRecommendations
